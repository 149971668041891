<template>
  <div>
    <FormGroup id="name" v-model="form.name" type="text" :disabled="disabled" label="Name" />
    <FormGroup id="description" v-model="form.description" type="textarea" :disabled="disabled" label="Description" />

    <div v-if="permissions.length === 0">
      <label>Permissions</label>
      <div class="alert alert-warning">No permissions found.</div>
    </div>
    <FormGroup
      v-else
      id="permissionIds"
      v-model="form.permissionIds"
      type="select-array-list"
      label="Permissions"
      :error="error['permissionIds']"
      :options="permissionOptions"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'RoleForm',
  components: {
    FormGroup
  },
  extends: Form,
  computed: {
    ...mapGetters({
      permissions: 'permission/permissions',
      permissionLoadingAction: 'permission/loadingAction',
      roleLoadingAction: 'role/loadingAction'
    }),
    permissionOptions() {
      const permissions = [...this.permissions];

      permissions.sort((a, b) => a.name.localeCompare(b.name));

      return permissions.map(permission => ({
        label: permission.name,
        value: permission._id
      }));
    }
  },
  mounted() {
    this.listPermissions();
  },
  methods: {
    ...mapActions({
      listPermissions: 'permission/list'
    })
  }
};
</script>
